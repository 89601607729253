const tracks = [
  {
    name: "In The Laundromat",
    url: "tracks/inthelaundromat.mp3",
  },
  {
    name: "Trackhound I",
    url: "tracks/trackhoundi.mp3",
  },
  {
    name: "Trackhound II",
    url: "tracks/tranckhoundii.mp3",
  },
];

export { tracks };

/*
Each track should be listed as follows in the above array 

  {
    name: "Track Name",
    url: "tracks/track_name.mp3",
  },

*/
