<script>
	import { Menu, Container, AudioPlayer } from "./components"
	import { Bio, Downloads, Images, Tracks, Videos} from "./partitions"
	import { defaults } from "./file_variables"

	const pages = [
		{name: 'Bio', component: Bio},
		{name: 'Tracks', component: Tracks},
		{name: 'Gallery', component: Images},
		{name: 'Videos', component: Videos},
		{name: 'Downloads', component: Downloads},
	]

	let selectedPage=0
	let trackPlaying = false
	let currentPlayingTrack=""
</script>

<svelte:head>
	<title>{defaults.artist} - {pages[selectedPage]["name"]}</title>
	<meta property="og:title" content="{defaults.artist} - {defaults.release_name}" />
	<meta property="og:description" content="EPK for {defaults.artists} - {defaults.release_name}" />
	<meta property="og:image" content={defaults.cover_image} />
</svelte:head>

<Menu bind:selectedPage/>

<div class="bx--content-container">
	<svelte:component 
		this={pages[selectedPage]["component"]} 
		bind:trackPlaying 
		bind:currentPlayingTrack
	/>
</div>
<AudioPlayer bind:trackPlaying bind:currentPlayingTrack/>

