const images = [
  {
    alt: "EARL GREEN",
    url: "images/1.jpg",
  },
  {
    alt: "EARL GREEN",
    url: "images/2.jpg",
  },
  {
    alt: "EARL GREEN",
    url: "images/3.jpg",
  },
];

export { images };

/* 
Each image should be listed as the following in above array 

  {
    alt: "image alt text",
    url: "images/imagename.extension",
  },

*/
