const videos = [
  {
    title: "In The Laundromat",
    videoId: "JPZoXhWOHZI",
  },
];

export { videos };

/* 
Each video should be listed as follows in the above array 

  {
    title: "video title",
    videoId: "youtube video id",
  },

*/
