const downloads = [];

export { downloads };

/*
Each download should be listed as follows in above array
{
  name: "Name of download",
  url: "downloads/name_of_file.extension",
}
*/
